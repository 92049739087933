import React from "react";
import Layout from "../../../components/Layout";
import Seo from "../../../components/seo";
import ogIMage from "../../../images/og.png";
import { Link, graphql, useStaticQuery } from "gatsby";
import ProjectGrid from "../../../components/ProjectGrid";
import NavigateBlock from "../../../components/NavigateBlock";

const GenAICaseStudiesPage = () => {
    const aicasestudies = useStaticQuery(graphql`
        {
            allContentfulCasestudies(
                sort: { order: DESC, fields: createdAt }
                filter: { type: { eq: "AI" } }
            ) {
                nodes {
                    id
                    name
                    logo {
                        gatsbyImageData(placeholder: BLURRED, quality: 100)
                        title
                    }
                    services {
                        id
                        title
                    }
                    description {
                        description
                    }
                    doneBy {
                        name
                    }
                    backgroundType
                    backgroundMedia {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                        title
                        file {
                            contentType
                            url
                        }
                    }
                    colorTheme
                    thumbnailTheme
                    slug
                    type
                }
            }
        }
    `);
    return (
        <Layout>
            <Seo
                title="GenAI Case Studies - Sedin"
                description="Explore how our GenAI-powered solutions streamline operations, drive growth, revolutionize the workflow and unlock limitless opportunities for your business."
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section className="AI-common-herostyle">
                <div className="container container--first">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                GenAI-Case-Studies
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x">
                            Weaving Stories of Success: Bridging Vision with Action
                        </h1>
                    </div>
                </div>
            </section>
            <section className="casestudies-projects">
                <div className="container container--py">
                    <ProjectGrid
                        CaseStudiesData={aicasestudies.allContentfulCasestudies.nodes}
                        ai
                    />
                </div>
            </section>
            <section className="genAI-casestudies-navigate">
                <div className="container container--last">
                    <NavigateBlock />
                </div>
            </section>
        </Layout>
    );
};

export default GenAICaseStudiesPage;
